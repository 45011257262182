import firebase from 'firebase/app';
import 'firebase/firestore';

// Get a Firestore instance
const db = firebase
  .initializeApp({projectId: process.env.VUE_APP_GOOGLE_PROJECT_ID})
  .firestore();
if (
  process.env.VUE_APP_FIRESTORE_EMULATOR_HOSTNAME &&
  process.env.VUE_APP_FIRESTORE_EMULATOR_PORT
) {
  // eslint-disable-next-line no-console
  console.log(
    'Running with emulated firestore at ' +
      process.env.VUE_APP_FIRESTORE_EMULATOR_HOSTNAME +
      ':' +
      process.env.VUE_APP_FIRESTORE_EMULATOR_PORT
  );
  db.useEmulator(
    process.env.VUE_APP_FIRESTORE_EMULATOR_HOSTNAME,
    process.env.VUE_APP_FIRESTORE_EMULATOR_PORT
  );
}
export default db;
