






























import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class PlayableVideoThumbnail extends Vue {
  @Prop({
    required: true,
    type: Number,
    default: 320,
  })
  readonly maxWidth!: number;

  @Prop({
    required: true,
    type: String,
  })
  readonly src!: string;

  @Prop({
    required: true,
    type: String,
  })
  readonly videoId!: string;

  private isPlaying = false;

  private get aspectRatio(): number {
    return 16 / 9;
  }

  private get maxHeight(): number {
    return this.maxWidth / this.aspectRatio;
  }

  private get youtubeEmbeddedPlayerUri(): string {
    return (
      'https://www.youtube-nocookie.com/embed/' + this.videoId + '?autoplay=1'
    );
  }

  private playVideo(): void {
    this.isPlaying = true;
  }
}
