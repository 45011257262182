
























































import PlayableVideoThumbnail from '@/components/PlayableVideoThumbnail.vue';
import db from '@/db';
import {PlaylistItem, PlaylistItemVote, Response} from '@/model';
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Prop as TypedProp} from 'vue/types/options';

@Component({
  components: {PlayableVideoThumbnail},
})
export default class PlaylistTableItem extends Vue {
  @Prop({
    required: true,
    type: Object as TypedProp<PlaylistItem>,
  })
  readonly playlistItem!: PlaylistItem;

  @Prop({
    required: false,
    type: Object as TypedProp<PlaylistItemVote>,
    default: undefined,
  })
  readonly playlistItemVote?: PlaylistItemVote;

  get youtubeUri(): string {
    const videoId = this.playlistItem.snippet?.resourceId?.videoId;
    return (
      'https://www.youtube.com/watch?v=' +
      videoId +
      '&list=' +
      process.env.VUE_APP_INPUT_PLAYLIST_ID
    );
  }

  get isItemCheckedPositiveBySoeren(): boolean {
    return Boolean(
      this.playlistItemVote && this.playlistItemVote.soerenVote === true
    );
  }

  get isItemCheckedNegativeBySoeren(): boolean {
    return Boolean(
      this.playlistItemVote && this.playlistItemVote.soerenVote === false
    );
  }

  get isItemCheckedPositiveByMarcel(): boolean {
    return Boolean(
      this.playlistItemVote && this.playlistItemVote.marcelVote === true
    );
  }

  get isItemCheckedNegativeByMarcel(): boolean {
    return Boolean(
      this.playlistItemVote && this.playlistItemVote.marcelVote === false
    );
  }

  get isItemCheckedPositiveByBoth(): boolean {
    return (
      this.isItemCheckedPositiveBySoeren && this.isItemCheckedPositiveByMarcel
    );
  }

  get isItemCheckedNegativeByBoth(): boolean {
    return (
      this.isItemCheckedNegativeBySoeren && this.isItemCheckedNegativeByMarcel
    );
  }

  private positiveCheckBySoeren(): void {
    this.addOrUpdateVote('soerenVote', true);
  }

  private negativeCheckBySoeren(): void {
    this.addOrUpdateVote('soerenVote', false);
  }

  private positiveCheckByMarcel(): void {
    this.addOrUpdateVote('marcelVote', true);
  }

  private negativeCheckByMarcel(): void {
    this.addOrUpdateVote('marcelVote', false);
  }

  private addOrUpdateVote(voteKey: string, vote: boolean): void {
    if (this.playlistItemVote) {
      db.collection('playlistItemVotes')
        .doc(this.playlistItemVote.id)
        .update({[voteKey]: vote});
    } else {
      db.collection('playlistItemVotes').add({
        playlistItemId: this.playlistItem.id,
        [voteKey]: vote,
      });
    }
  }

  private removeVote(): void {
    if (this.playlistItemVote) {
      db.collection('playlistItemVotes')
        .doc(this.playlistItemVote.id)
        .delete();
    }
  }

  private addPositiveItem(): void {
    this.addToYoutubePlaylist().then(() => {
      this.deleteFromYoutubePlaylist().then(() => {
        this.removeVote();
        this.$emit('itemDeleted');
      });
    });
  }

  private deleteNegativeItem(): void {
    this.deleteFromYoutubePlaylist().then(() => {
      this.removeVote();
      this.$emit('itemDeleted');
    });
  }

  private async deleteFromYoutubePlaylist(): Promise<Response<void>> {
    if (!this.playlistItem.id) {
      return Promise.reject();
    }
    return (
      await this.$gapi.getGapiClient()
    ).client.youtube.playlistItems.delete({id: this.playlistItem.id});
  }

  private async addToYoutubePlaylist(): Promise<Response<PlaylistItem>> {
    return (
      await this.$gapi.getGapiClient()
    ).client.youtube.playlistItems.insert({
      part: ['snippet'],
      resource: {
        snippet: {
          playlistId: process.env.VUE_APP_OUTPUT_PLAYLIST_ID,
          resourceId: {
            kind: 'youtube#video',
            videoId: this.playlistItem.snippet?.resourceId?.videoId,
          },
        },
      },
    });
  }
}
