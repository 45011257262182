



















































































import PlaylistTableItem from '@/components/PlaylistTableItem.vue';
import db from '@/db';
import {PlaylistItem, PlaylistItemVote, Update} from '@/model';
import {Component, Vue, Watch} from 'vue-property-decorator';

@Component({
  components: {PlaylistTableItem},
  firestore() {
    return {
      playlistItemVotes: db.collection('playlistItemVotes'),
      updates: db.collection('updates'),
    };
  },
})
export default class PlaylistTable extends Vue {
  private playlistItems: PlaylistItem[] = [];
  private playlistItemVotes: PlaylistItemVote[] = [];

  private updates: Update[] = [];
  private lastUpdated = 0;

  private loading = true;
  private search = '';
  private sortBy = 'snippet.position';
  private sortDesc = false;
  private sortKeys: {value: string; text: string}[] = [
    {value: 'snippet.position', text: 'Position'},
    {value: 'snippet.title', text: 'Title'},
  ];
  private filterBy = 'all';
  private filterKeys: {
    value: string;
    icon?: string;
    label?: string;
    tooltip: string;
  }[] = [
    {value: 'all', icon: 'mdi-eye', tooltip: 'Show all'},
    {
      value: 'ready-for-decision',
      icon: 'mdi-thumbs-up-down',
      tooltip: 'Show ready for decision',
    },
    {
      value: 'soeren-decided',
      label: 'S',
      tooltip: 'Show entries with a decision by Sören',
    },
    {
      value: 'marcel-decided',
      label: 'M',
      tooltip: 'Show entries with a decision by Marcel',
    },
  ];

  get playlistItemVotesById(): Record<string, PlaylistItemVote> {
    const sorted: Record<string, PlaylistItemVote> = {};
    let playlistItemVote: PlaylistItemVote;
    for (playlistItemVote of this.playlistItemVotes) {
      sorted[playlistItemVote.playlistItemId] = playlistItemVote;
    }
    return sorted;
  }

  async created(): Promise<void> {
    await this.loadPlaylistItems();
  }

  private async loadPlaylistItems(): Promise<void> {
    this.loading = true;
    let newPlaylistItems: PlaylistItem[] = [];
    const gapi = await this.$gapi.getGapiClient();
    let response = await gapi.client.youtube.playlistItems.list({
      playlistId: process.env.VUE_APP_INPUT_PLAYLIST_ID,
      maxResults: 50,
      part: 'snippet',
    });
    newPlaylistItems = newPlaylistItems.concat(response.result.items ?? []);

    while (response.result.nextPageToken) {
      response = await gapi.client.youtube.playlistItems.list({
        playlistId: process.env.VUE_APP_INPUT_PLAYLIST_ID,
        maxResults: 50,
        part: 'snippet',
        pageToken: response.result.nextPageToken,
      });

      newPlaylistItems = newPlaylistItems.concat(response.result.items ?? []);
    }

    this.playlistItems = newPlaylistItems;
    this.lastUpdated = Date.now();
    this.loading = false;
  }

  private get filteredPlaylistItems(): PlaylistItem[] {
    let result = this.playlistItems;
    if (this.search !== null) {
      const lowerCaseSearch = this.search.toLowerCase();
      result = result.filter((item) =>
        this.filterBySearchTerm(item, lowerCaseSearch)
      );
    }
    if (this.filterBy === 'ready-for-decision') {
      result = result.filter(this.filterByReadyForDecision);
    } else if (this.filterBy === 'soeren-decided') {
      result = result.filter(this.filterBySoerenDecided);
    } else if (this.filterBy === 'marcel-decided') {
      result = result.filter(this.filterByMarcelDecided);
    }
    return result;
  }

  private filterBySearchTerm(
    item: PlaylistItem,
    lowerCaseSearch: string
  ): boolean {
    const title = item.snippet?.title?.toLowerCase();
    return title !== undefined && title.includes(lowerCaseSearch);
  }

  private filterByReadyForDecision(item: PlaylistItem): boolean {
    if (item.id) {
      const vote = this.playlistItemVotesById[item.id];
      return (
        vote && vote.soerenVote !== undefined && vote.marcelVote !== undefined
      );
    }
    return false;
  }

  private filterBySoerenDecided(item: PlaylistItem): boolean {
    if (item.id) {
      const vote = this.playlistItemVotesById[item.id];
      return vote && vote.soerenVote !== undefined;
    }
    return false;
  }

  private filterByMarcelDecided(item: PlaylistItem): boolean {
    if (item.id) {
      const vote = this.playlistItemVotesById[item.id];
      return vote && vote.marcelVote !== undefined;
    }
    return false;
  }

  private createNewUpdate(): void {
    db.collection('updates').add({timestamp: Date.now()});
  }

  @Watch('updates')
  async onUpdatesChanged(value: Update[]): Promise<void> {
    if (value.length === 0 || this.lastUpdated === 0) {
      return;
    }

    const sorted = Array.from(value);
    sorted.sort((a, b) => {
      if (a.timestamp < b.timestamp) return -1;
      if (a.timestamp > b.timestamp) return 1;
      return 0;
    });

    if (sorted[sorted.length - 1].timestamp > this.lastUpdated) {
      await this.loadPlaylistItems();
      sorted.slice(0, sorted.length - 1).forEach((item) => {
        db.collection('updates').doc(item.id).delete();
      });
    }
  }
}
